import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from './contact.module.scss'
import Container from "../components/container"
import {Icon} from '@iconify/react';
import phoneFilled from '@iconify/icons-ant-design/phone-filled';
import baselineMail from '@iconify/icons-ic/baseline-mail'


const ContactPage = ({location}) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    switch (name) {
      case 'name':
        setName(value)
        break
      case 'email':
        setEmail(value)
        break
      case 'phone':
        setPhone(value)
        break
      case 'message':
        setMessage(value)
        break
      default:
        break
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    alert(`${name} ${email} ${phone} ${message}`)
  }

  return (
    <Layout location={location}>
      <SEO title="Home"/>
      <div className={styles.contactPage}>
        <section className={styles.infoSection}>
          <Container>
            <h2>You can contact me with this</h2>
            <div className={styles.info}>
              <div className={styles.infoItem}>
                <Icon className={styles.infoItemIcon} icon={phoneFilled}/>
                <div className={styles.infoItemContent}>
                  <div className={styles.infoItemContentField}>My phone</div>
                  <div className={styles.infoItemContentValue}>+52 755 108 1695</div>
                </div>
              </div>
              <div className={styles.infoItem}>
                <Icon className={styles.infoItemIcon} icon={baselineMail}/>
                <div className={styles.infoItemContent}>
                  <div className={styles.infoItemContentField}>My email</div>
                  <div className={styles.infoItemContentValue}>amadogr50@gmail.com</div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        {/*<section className={styles.contactSection}>
          <Container>
            <h2>Or you can send me a message</h2>
            <form className={styles.contactForm} onSubmit={handleSubmit}>
              <label>
                Your name
                <input
                  className={styles.formField}
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleInputChange}/>
              </label>
              <label>
                Your email
                <input
                  className={styles.formField}
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}/>
              </label>
              <label>
                Your phone
                <input
                  className={styles.formField}
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={handleInputChange}/>
              </label>
              <label>
                What do you need?
                <input
                  className={styles.formField}
                  type="text"
                  name="message"
                  value={message}
                  onChange={handleInputChange}/>
              </label>
              <button className={styles.submitButton} type="submit">Send</button>
            </form>
          </Container>
        </section>*/}
      </div>
    </Layout>
  )
}

export default ContactPage
